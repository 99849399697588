export const routes = Object.freeze({
  home: "/",
  notFound: "/not-found",
  dashboard: "/dashboard",
  privacyPolicy: "/privacy-policy",
  signin: "/signin",
  signup: "/signup",
  settings: "/settings",
  verification: "/verification/profile",
  verificationSubscriptionConfirm: "/verification/profile/my-subscription",
  massSearchOffersAvailable: "/mass-search/:id/offers-available",
  massSearchOffersNotAvailable: "/mass-search/:id/offers-not-available",
  massSearchOffersUnknown: "/mass-search/:id/offers-unknown",
  basket: "/basket",
  basketDeliveryAddress: "/basket/delivery-address",
  basketCheckout: "/basket/checkout",
  cookies: "/cookies",
} as const);

export const routeHandlers = Object.freeze({
  signout: "/routes/signout",
});

export const blockedRoutes: string[] = [routes.home];
export const authRoutes: string[] = [routes.signin, routes.signup, routes.verification];
export const publicRoutes: string[] = [
  routes.home,
  routes.signin,
  routes.signup,
  routes.verification,
  routes.verificationSubscriptionConfirm,
  routes.privacyPolicy,
  routes.notFound,
  routes.cookies,
];

export const knownRoutes: string[] = [...Object.values(routes), ...Object.values(routeHandlers)];

export const PRIVATE_DEFAULT_ROUTE = routes.dashboard;

export const PUBLIC_DEFAULT_ROUTE = routes.signin;
